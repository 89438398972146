* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
html body {
  color: #333;
  font-size: 16px;
  font-family: PingFangSC;
  background-color: #fff;
}

html,
body,
#root {
  height: 100%;
  background-color: #f5f5f5;
}

body,
h1,
h3,
p {
  margin: 0;
  padding: 0;
}

button {
  display: block;
  outline: 0 none;
  padding: 0;
  border-radius: 8px;
  // -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  outline: none;
  border: 0;
}

input {
  display: block;
  margin: 0;
  padding: 0;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
  color: #333;
  &:focus-visible {
    outline: none;
  }
}

textarea {
  line-height: 6.8vw;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  overflow: visible;
  display: block;
  resize: none;
  word-break: break-word;
  word-wrap: break-word;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
